import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
    <h1>Welcome to my website</h1>
    <button id="get-message-btn">Get Happy Message</button>
    <div id="message"></div>
    <script src="index.js"></script>
      </header>
    </div>
  );
}

export default App;
